import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { useQuery } from '@apollo/client';
import ModalTiny from "react-bootstrap/Modal"
import gql from "graphql-tag"
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import parse from 'html-react-parser';

import logoBlack from "../../../images/redbrik-new-logo.svg";
import Header from "../../Header/Header"
import Footer from "../../Footer/Footer"
import Instagram from "../../Instagram"
import Contact from "../../forms/contact-form"
import mail from "../../../images/mail.svg";
import mailwhite from "../../../images/mail-white.svg";
import { isMobileOnly, isMobile, isTablet } from "react-device-detect"

import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb'
import "./AboutPage.scss";
import ScrollAnimation from 'react-animate-on-scroll';
import Seo from "../../seo";
// Header component


const SINGLE_TEAM = gql`
query GETTEAM($URL: String!){
    peoples(where:{URL: $URL}){
        id
        Name
        Designation
        Image {
        url
        alternativeText
        }
        Meta_Title
        Meta_Description
        Phone
        Email
        About
        imagetransforms
    }
}
`;

function AboutPage(props) {
    const [isPlay, setPlay] = useState(false);

    const [ formLabel, setFormLabel] = React.useState("")

	const { loading, error, data } = useQuery(SINGLE_TEAM, {
        variables: { URL: props.slug }
      });


    const [modalSharefrndformOpen,setSharefrndformOpen] = React.useState(false);

    const openSharefrndformModal = () => {
        setSharefrndformOpen(true);
    }
    const closeSharefrndformModal = () => {
        setSharefrndformOpen(false);
    }

    if (loading) return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img className="logo-white loader-logo" src={logoBlack} alt="logo" />
            </div>
            <div class="loader-section section-left"></div>
            <div class="loader-section section-right"></div>
          </div>
        </section>
    )

    const getBreadcrumb = (list) => {
        let bread = [
            { Title: "About", Slug: "about-us/" },
            { Title: "Meet the team", Slug: "contact/meet-the-team/" }]
        if (list) {
            bread.push({ Title: list[0].Name})
        }
        return bread;
    }


    return (
        <React.Fragment>
            <Seo
                title={`${data?.peoples?.[0].Name}`} 
                description={`Get to know about ${data?.peoples?.[0].Name}  here. Contact one of our skilled, caring and passionate property professionals for assistance in your property journey.`} 
            />
            <div className="team-details-main">
            <Header/>
            <div className="team-details">
                <Breadcrumb staticList={getBreadcrumb(data?.peoples)} />
                {data.peoples.map((data, index) => {
                    const image_url = data.Image.url

                    let processedImages = JSON.stringify({});
                    if (data?.imagetransforms?.Image_Transforms) {
                        processedImages = data.imagetransforms.Image_Transforms;
                    }

                    var FullName = data.Name
                    var fname = []
                    var fname = FullName.split(' ');
                    // console.log("StudentFirstName="+details[0])
                    // console.log("StudentLastName="+details[1]);


                return (
                <section className="about-page">
                    <Container>
                        <Row>
                            <Col xs={12} sm={6} lg={7} className="col-sections">
                                <ScrollAnimation animateIn='fadeInRight' animateOnce={true}>
                                    <div className="about-info">
                                        <h2>{FullName}</h2>
                                        <span className="profile">
                                            {data.Designation}
                                        </span>
                                        {/* <a href="javascript:void(0)" onClick={()=> { setFormLabel("Book A Consultation"); openSharefrndformModal();}} className="btn btn_cray"> */}
                                        <a href={'mailto:' + data.Email} className="btn btn_cray">
                                        <img src={mail} alt="Email Icon" className="email_logo"/>
                                        <img src={mailwhite} alt="Email Icon" className="email_logo white"/>
                                        Email {fname[0]}
                                        </a>
                                        {/* {data.Email &&
                                        <a href="javascript:void(0)" onClick={()=> { setFormLabel("Person Contact"); openSharefrndformModal();}} className="mail">
                                            {data.Email}
                                        </a>
                                        } */}
                                        { data.About &&
                                            <>
                                                <div className="person-bio">
                                                    {parse(data.About)}
                                                </div>
                                            </>
                                        }
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col xs={12} sm={6} lg={5} className="col-sections">
                                <ScrollAnimation animateIn='fadeInLeft' animateOnce={true}>
                                    <div className="about-img img-zoom">

                                        <ImageTransform imagesources={image_url} renderer="srcSet" imagename="peoples.Image.details" attr={{ alt: data.Name + ' - Redbrik', class: '' }} imagetransformresult={processedImages} id={data.id} />
                                        {/* <div className="video-buttons">
                                            <strong
                                                className="video-btn"
                                                onClick={(e) => {
                                                    setPlay(true);
                                                }}
                                            >
                                                <i className="icon-video"></i>
                                            </strong>
                                        </div> */}
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <div className="btn-action">
                                {/* <a href="javascript:void(0)" onClick={()=> { setFormLabel("Book A Consultation"); openSharefrndformModal();}} className="btn btn_cray"> */}
                                <a href={'mailto:' + data.Email} className="btn btn_cray">
                                <img
                                    src={mail}
                                    alt="Email Icon"
                                    className="email_logo"
                                />
                                Email {fname[0]}
                                </a>
                            </div>
                        </Row>
                    </Container>

                    {isTablet && data.About &&
                        <>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <div className="tabletonly">
                                            {parse(data.About)}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    }

                    <ModalTiny show={modalSharefrndformOpen} onHide={closeSharefrndformModal} className="getin-touch book-a-viewing-modal">
                    <ModalTiny.Header closeButton className="contact-close-btn">
                        <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Contact {data.Name}</h2></ModalTiny.Title>
                    </ModalTiny.Header>

                    <ModalTiny.Body className="event-section">
                        <Contact to_email_id={data.Email} formLabel={formLabel}/>
                    </ModalTiny.Body>

                    </ModalTiny>

                </section>
                )
                })}
                </div>
                <Footer/>
            </div>
        </React.Fragment>
    );
};

export default AboutPage;
