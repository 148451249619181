import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import gql from "graphql-tag"
import logoBlack from "../../images/redbrik-new-logo.svg";
import Facebook from "../../images/social/facebook.png";
import Twitter from "../../images/social/twitter.png";
import Instagram from "../../images/social/instagram.png";
import _ from "lodash"
import parse from 'html-react-parser';
import SEO from "../seo"
import { useLocation } from "@reach/router"
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon
} from "react-share";

import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import SimilarNews from "../../components/ProperyDetails/SimilarProperties/SimilarNews"
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import Newsletter from "../StaticPage/StayUpdate/StayUpdate"

import quote from "../../images/quote.jpg"


import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const SINGLE_NEWS = gql`
query GETNEWS($URL: String!){
    newsBlogs(where:{URL: $URL}){
        id
        Title
        Date
        URL
        Meta_Title
        Meta_Description
        Tile_Image {
          url
          alternativeText
        }
        Short_Description
        Categories {
          Name
          URL
        }
        Banner_Image {
          url
          alternativeText
        }
        Author
        Add_Content {
            ...on ComponentModulesStaticContent {
            Add_Static_Content
            }
            ...on ComponentModulesQuote {
            Add_Quote
            }
            ...on ComponentModulesAddImage {
                Image {
                    url
                    alternativeText
                }
            }
        }
        imagetransforms
    }
    globalModule {
        Register_Withus_Module_Content
    }
}
`;

function NewsDetails(props) {

    const { loading, error, data } = useQuery(SINGLE_NEWS, {
        variables: { URL: props.slug }
    });

    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
    const [Shareicons, setShareicons] = React.useState(false);

    const openShareicons = () => {
        setShareicons(true);
        if (Shareicons === true) {
            setShareicons(false);
        }
    }

    const getBreadcrumb = (list) => {
        let bread = [
            { Title: "About", Slug: "about-us/" },
            { Title: "News", Slug: "about-us/property-news/" }]
        if (list) {
            bread.push({ Title: list[0].Title, Slug: `about-us/property-news/${`URL`}` })
        }
        return bread;
    }
    if (loading) return (
        <section className={"loader-wrapper"}>
            <div id="loader-wrapper">
                <div id="loader" class="new-loader">
                    <img className="logo-white loader-logo" src={logoBlack} alt="logo" />
                </div>
                <div class="loader-section section-left"></div>
                <div class="loader-section section-right"></div>
            </div>
        </section>
    )

    const Register_Withus_Module_Content = data?.globalModule?.Register_Withus_Module_Content;

    return (
        <React.Fragment>
            <div className=" news-details">
                <Header />
                <div className="collection-landing news-details">
                    <Breadcrumb staticList={getBreadcrumb(data?.newsBlogs)} />
                    {data.newsBlogs.map((data, index) => {

                        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                        var selectedMonthName = months[parseInt(data.Date.slice(5, 7)) - 1];

                        let image_url = ''
                        if (image_url) {
                            image_url = data.Banner_Image.url
                        }

                        // let processedImages = JSON.stringify({});
                        // if (data?.imagetransforms?.Banner_Image_Transforms) {
                        //     processedImages = data.imagetransforms.Banner_Image_Transforms;
                        // }
                        var cat_list = '#'

                        var cats = data.Categories

                        for (var i = 0; i < cats.length; i++) {
                            var full_name = cats[i].Name
                            cat_list += (cats[i].Name) + `${cats.length == 1 || i + 1 == cats.length ? '' : ', '}`
                        }

                        return (
                            <Container className="content">
                                <SEO
                                    title={`${data?.Meta_Title ? data.Meta_Title : data?.Title}`}
                                    description={data.Meta_Description}
                                />

                                <Row>
                                    <Col lg={1}>
                                    </Col>
                                    <Col lg={10}>
                                        <div className="heading">
                                            {/* <div className="date-info">
                                            <span className="date">
                                                {data.Date.slice(8, 10)}
                                            </span>
                                            <span className="month">
                                                {selectedMonthName}
                                            </span>
                                        </div> */}
                                            <div className="main-title">
                                                <h1>{data.Title}</h1>
                                                <Row>
                                                    <Col lg={8}>
                                                        <div className="social">
                                                        <span className="sm-info">
                                                            {data.Author && "Written by " + data.Author + " . "} {cat_list}
                                                        </span>
                                                        <span className="sm-info divider"> / </span>
                                                        <a href="javascript:void(0)" className="propertyShare" onClick={openShareicons}>Share this post</a>
                                                        {Shareicons &&
                                                            <div onClick={openShareicons} className="social-share">
                                                                <FacebookShareButton url={shareurl} className="my-share-button facebook-share">
                                                                    <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                                                </FacebookShareButton>
                                                                <TwitterShareButton url={shareurl} className="my-share-button twitter-share">
                                                                    <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                                                </TwitterShareButton>

                                                            </div>
                                                        }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        {data.Banner_Image &&
                                            <div className="news-main-banner">
                                                <img src={data.Banner_Image.url} alt={data.Banner_Image.alternativeText} />

                                                {/* <ImageTransform imagesources={image_url} renderer="srcSet" imagename="news-blogs.Banner_Image.details" attr={{ alt: data.Title+ ' - Redbrik', class:'' }} imagetransformresult={processedImages} id={data.id}/> */}
                                            </div>
                                        }

                                        {data.Add_Content.map((content, index) => {
                                            return (
                                                <>
                                                    {content.Add_Static_Content &&
                                                        <div className="row">
                                                            <div className="static-content col-lg-12">
                                                                {parse(content.Add_Static_Content)}
                                                            </div>
                                                        </div>
                                                    }

                                                    {content.Add_Quote &&
                                                        <div className="static-content-quote">
                                                            <img src={quote} alt="" />
                                                            <div className="text">
                                                                {parse(content.Add_Quote)}
                                                            </div>
                                                        </div>
                                                    }
                                                    {content.Image &&
                                                        <div className="static-content-images row">
                                                            {content.Image.map((image, index) => {
                                                                return (
                                                                    <div className={`static-img ${content.Image.length > 1 ? "col md-6 col-lg-6" : "col-lg-12"}`}>
                                                                        <img src={image.url} alt="" />
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    }
                                                </>
                                            )
                                        })}

                                        <div className="row">
                                            <div className="col-lg-12">
                                                {/* <Newsletter /> */}
                                                {Register_Withus_Module_Content &&
                                                    <div className="static-register-withus-module">
                                                        {parse(Register_Withus_Module_Content)}
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </Col>
                                    <Col lg={1}>
                                    </Col>
                                </Row>
                            </Container>
                        )
                    })}
                    <SimilarNews slug={props.slug} />
                </div>
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default NewsDetails;
