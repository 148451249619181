import React, { useState, useEffect } from "react";
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import { useMatch ,useLocation ,navigate } from "@reach/router"

import Header from "../components/Header/Header"
import Modules from '../components/modules'
import NewsDetailsPage from '../components/NewsDetails/NewsDetails'
import GuideDetailsPage from '../components/GuideDetails/GuideLandingPage'
import TeamDetailsPage from '../components/StaticPage/AboutPage/AboutPage'
import CareerDetailsPage from '../components/CareerDetailsPage/CareerDetailsPage'
import RecommendsDetailsPage from '../components/AreaGuides/RecommendsDetailsPage'
import ImageBlockSlideDetailsPage from '../components/AreaGuides/ImageBlockSlideDetailsPage'
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import Footer from "../components/Footer/Footer"
import Instagram from "../components/Instagram"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";

import { LATEST_NEWS_PAGE_URL, MEET_THE_TEAM_PAGE_URL, CAREERS_PAGE_URL, GUIDE_PAGE_URL, HOMEANDINTERIORS_PAGE_URL, RECOMMENDATIONS_PAGE_URL } from "../components/common/site/constants";


const DefaultTemplate = (props) => {

  const [render, setRender] = useState(true);

  const GQLPage = props.data.glstrapi?.article;
  const Page_Modules = props.data.glstrapi?.article?.Modules;

  const NewsDetails = useMatch(LATEST_NEWS_PAGE_URL.alias + "/:slug")
  const GuideDetails = useMatch(GUIDE_PAGE_URL.alias + "/:slug")
  const TeamDetails = useMatch(MEET_THE_TEAM_PAGE_URL.alias + "/:slug")
  const CareerDetails = useMatch(CAREERS_PAGE_URL.alias + "/:slug")
  const HomeAandInteriors = useMatch(HOMEANDINTERIORS_PAGE_URL.alias + "/:slug")
  const recommendsDetails = useMatch(RECOMMENDATIONS_PAGE_URL.alias + "/:slug") 

  const { pathname } = useLocation()
  //console.log("pathname",pathname)
  if(pathname.includes("/contact/meet-the-team/")){
    var slug = pathname.split("/")
    //console.log("slug",slug,slug.length)
    if(slug?.length > 5){
        navigate('/contact/meet-the-team/')
    }
  }
  

  console.log('GQLPage', GQLPage)
  let custom_css = ''
  if (GQLPage.Custom_CSS_Class) {
    custom_css = GQLPage.Custom_CSS_Class
  }

  let add_css = ''
  if ((GQLPage.Layout == "Collection_Landing") && GQLPage.Pagename != "Home") {
    add_css = "collection-landing"
  } else if ((GQLPage.Layout != "Form") && GQLPage.Pagename != "Home") {
    add_css = "default-landing"
  }

  useEffect(() => {
    setRender(true)
  }, [])


  if (NewsDetails !== null && render) {
    var URL = NewsDetails.slug
    // var MAIN_URL = NewsDetails.item
    return ( 
        <NewsDetailsPage slug={URL} /> 
    )
  }

  if (GQLPage.Layout == "Guide_Page" && render) { 
    return ( 
        <GuideDetailsPage slug={`your-guide-to-upsizing`} {...GQLPage} /> 
    )
  }

  

  if (TeamDetails !== null) {
    var URL = TeamDetails.slug
    // var MAIN_URL = NewsDetails.item
    return (
      <>
        {render &&
          <TeamDetailsPage slug={URL} />
        }
      </>
    )
  }


  if (CareerDetails !== null) {
    var URL = CareerDetails.slug
    // var MAIN_URL = NewsDetails.item
    return (
      <>
        {render &&
          <CareerDetailsPage slug={URL} />
        }
      </>
    )
  }

  if (recommendsDetails !== null) {
    var URL = recommendsDetails.slug
    // var MAIN_URL = NewsDetails.item
    return ( 
        <RecommendsDetailsPage slug={URL} /> 
    )
  }
  
  if (HomeAandInteriors !== null) {
    var URL = HomeAandInteriors.slug
    // var MAIN_URL = NewsDetails.item
    return ( 
        <ImageBlockSlideDetailsPage slug={URL} /> 
    )
  }

  if (NewsDetails === null && TeamDetails === null && CareerDetails === null && GQLPage.Layout != "Guide_Page" && render) {
    return (
      <div className={custom_css}>
        <SEO title={GQLPage ? GQLPage.Meta_Title : ''} description={GQLPage ? GQLPage.Meta_Description : ''} />
        <Header />
        <div className={add_css}>
          {(GQLPage.Layout != "Form") && GQLPage.Pagename != "Home" &&
            <Breadcrumb menus={GQLPage.Choose_Menu} />
          }

          <Modules page={GQLPage} modules={Page_Modules} {...props} />
        </div>
        <Footer popular_search={GQLPage.Popular_Search} />
      </div>
    )
  }
}

export default DefaultTemplate


export const pageQuery = graphql`
  query defaultQuery($id: ID!) {
      glstrapi {
        article(id: $id, publicationState: LIVE) {
            Banner_CTA_Label
            Banner_Main_Content
            Banner_Title_Heading
            Banner_Video_Intro_Content
            Banner_Content_Alignment
            Banner_Video_Link
            Custom_CSS_Class
            Meta_Description
            Layout
            imagetransforms
            Meta_Title
            Page_Tags
            Pagename
            Popular_Search
            Choose_Menu {
              Label
              URL
              Main_Parent {
                Label
                URL
              }
            }
            _id
            Banner_CTA_Link {
              id
            }
            Banner_Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(formats: AUTO
                    width: 1800
                    placeholder: BLURRED
                    quality: 80
                    layout: FIXED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )
                  fluid(quality: 90, maxWidth: 1800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            Modules {
              ... on GLSTRAPI_ComponentModulesSlider {
                id
                Slider_CTA_2_Label
                Slider_Content
                Slider_Info_Title
                Slider_Image {
                  url
                  alternativeText
                  url_sharp {
                    childImageSharp {
                        gatsbyImageData(formats: AUTO
                          width: 1920
                          placeholder: BLURRED
                          quality: 100
                          transformOptions: {cropFocus: CENTER, fit: COVER}
                        )
                        fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
                Mobile_Banner_Image
                {
                  url
                  alternativeText
                  url_sharp {
                    childImageSharp {
                        gatsbyImageData(formats: AUTO
                          width: 1200
                          placeholder: BLURRED
                          quality: 100
                          transformOptions: {cropFocus: CENTER, fit: COVER}
                        )
                        fluid(quality: 100, maxWidth: 1200) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
                Slider_CTA_2_Link {
                  id
                }
                Slider_CTA_1_Label
                Slider_CTA_1_Link {
                  id
                }
                Ctas {
                  Submenu_Label
                  Submenu_Link {
                    URL
                  }
                }
              }
              ...on GLSTRAPI_ComponentModulesAddImage {
                ModuleImage  : Image {
                    url
                    alternativeText
                }
              }
              ... on GLSTRAPI_ComponentRevampRevampBanner {
                __typename
                id
                Pre_Title
                Heading
                Sub_Heading
                Content
                Show_Review
                Image {
                  url
                  alternativeText
                  url_sharp {
                    childImageSharp {
                        gatsbyImageData(formats: AUTO
                          width: 1920
                          placeholder: BLURRED
                          quality: 100
                          transformOptions: {cropFocus: CENTER, fit: COVER}
                        )
                        fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
                Buttons {
                  Label
                  Icon
                  Color
                  custom_url
                  Link {
                    URL
                    id
                    Main_Parent {
                      URL
                    }
                  }
                }
              }
              ... on GLSTRAPI_ComponentRevampDoubleImageBlock {
                __typename
                id
                Image_block1 {
                  url
                }
                Image_block2 {
                  url
                }
                content1
                content2
                cta1name
                customUrl1
                cta1link {
                  URL
                  main_parent {
                    URL
                  }
                }
                cta2name
                customUrl2
                cta2link {
                  URL
                  main_parent {
                    URL
                  }
                }
              }
              ... on GLSTRAPI_ComponentRevampAwards {
                __typename
                id
                content
                award_icons {
                  url
                }
              }
              ... on GLSTRAPI_ComponentModulesIntro {
                id
                Intro_CTA_Label
                Intro_Content_One
                Intro_Content_Two
                Intro_Info_Title
                Title_SM
                Intro_Title
                Intro_CTA_Link {
                  id
                }
              }
              ... on GLSTRAPI_ComponentModulesServices {
                id
                Services_CTA_Label
                Services_Title
                Service_Tile {
                  Service_Tile_Image {
                    url
                    alternativeText
                    url_sharp {
                      childImageSharp {
                        gatsbyImageData(placeholder:BLURRED)
                      }
                    }
                  }
                  Service_Tile_Label
                  Service_Tile_Link {
                    id
                  }
                }
                Services_CTA_Link {
                  id
                }
              }
              ... on GLSTRAPI_ComponentRevampOurServices {
                __typename
                id
                services_title
                services_content
                services_list {
                  id
                  services_list_content
                  services_list_icons {
                    url
                  }
                  services_name
                }
              }
              ... on GLSTRAPI_ComponentModulesVideo {
                id
                Video_Content
                Video_Embed_URL
                Video_Short_Info
                Video_Style
                Video_Background_Image {
                  url
                  alternativeText
                  url_sharp {
                    childImageSharp {
                      gatsbyImageData(formats: AUTO
                        width: 1800
                        placeholder: BLURRED
                        quality: 80
                        layout: FIXED
                        transformOptions: {cropFocus: CENTER, fit: COVER}
                      )
                      fluid(quality: 90, maxWidth: 1800, maxHeight:1050,cropFocus:ENTROPY, fit:INSIDE) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                Custom_Class
              }
              ... on GLSTRAPI_ComponentModulesGetInTouch {
                id
                Get_in_Touch_CTA_1_Label
                Get_in_Touch_CTA_2_Label
                Get_in_Touch_Content
                Get_in_Touch_Background_Image {
                  url
                  alternativeText
                  url_sharp {
                    childImageSharp {
                      gatsbyImageData(placeholder:BLURRED)
                    }
                  }
                }
                Get_in_Touch_CTA_1_Link {
                  id
                }
                Get_in_Touch_CTA_2_Link {
                  id
                }
              }
              ... on GLSTRAPI_ComponentModulesGlobalModules {
                Select_Module
                Bold_Title
                Title
                BackgroundColor: BG_Color
                id
              }
              ... on GLSTRAPI_ComponentRevampTeamSlider {
                __typename
                id
                Bold_Title
                Title
                peoples {
                  Name
                  URL
                  id
                  Designation
                  Email
                  Image {
                    url
                  }
                  Choose_Category {
                    URL
                  }
                  imagetransforms
                }
              }
              ... on GLSTRAPI_ComponentRevampFaq {
                __typename
                id
                Title
                bold_title
                bg_color
                Content
                FAQ_List {
                  Question
                  Answer
                }
              }
              ... on GLSTRAPI_ComponentRevampContentBlock {
                __typename
                id
                Title
                Content
                Bold_Title
                CTA_Block {
                  Cta_Label
                  Cta_Link
                  Target
                }
              }
              ... on GLSTRAPI_ComponentRevampImageContentBlock {
                __typename
                id
                Image {
                  alternativeText
                  url
                }
                custom_class
                Image_Info
                Show_Review
                Video_Url
                Video_Upload {
                  url
                }
                Video_Embed_Code
                Content
                Buttons {
                  custom_url
                  Label
                  Icon
                  Color
                  id
                  Link {
                    URL
                    id
                  }
                }
              }
              ... on GLSTRAPI_ComponentRevampStatsModule {
                __typename
                id
                Intro
                custom_class
                Stats {
                  Title
                  Prefix
                  Info
                  Suffix                  
                  CTA_Label
                  CTA_Link {
                    URL
                    id
                    Main_Parent {
                      URL
                    }
                  }
                }
              }
              ... on GLSTRAPI_ComponentRevampServiceBlock {
                __typename
                id
                Image {
                  alternativeText
                  url
                }
                BG_Color
                Image_Align
                Map_Link
                Content
                Custom_Class
                reviews
                Initially_Animate
                Buttons {
                  Color
                  Icon
                  Label
                  custom_url
                  Link {
                    URL
                    id
                    Main_Parent {
                      URL
                    }
                  }
                }
              }
              ... on GLSTRAPI_ComponentRevampOurPakage {
                __typename
                id
                Title
                _id
                content_block {
                  Heading
                  Title
                  _id
                  Button_Name
                  Button_Link {
                    URL
                  }
                  content
                  id
                }
              }
               ... on GLSTRAPI_ComponentRevampOurPakage {
                __typename
                id
                Title
                _id
                content_block {
                  Heading
                  Title
                  _id
                  Button_Name
                  Button_Link {
                    URL
                  }
                  content
                  id
                }
              }             
              ... on GLSTRAPI_ComponentRevampBannerValuation {
                __typename
                id
                Title
                Content
                Image {
                  alternativeText
                  url
                  url_sharp {
                    childImageSharp {
                      gatsbyImageData(formats: AUTO
                        width: 1800
                        placeholder: BLURRED
                        quality: 80
                        layout: FIXED
                        transformOptions: {cropFocus: CENTER, fit: COVER}
                      )
                      fluid(quality: 90, maxWidth: 1800) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                Show_Google_Review
                Content_Block {
                  Bold_Title
                  Title
                  Content
                  CTA_Block {
                    Cta_Label
                    Cta_Link
                    Target
                    id
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesSingleContentBlockWithImage {
                id
                Single_Block_CTA_Label
                Single_Block_Content
                Single_Block_Info_Title
                Single_Block_CTA_Link {
                  id
                }
                Single_Block_Image {
                  alternativeText
                  url
                }
              }
              ... on GLSTRAPI_ComponentModulesCollections {
                id
                Select_Collection
                Title
                bold_title
              }
              ... on GLSTRAPI_ComponentModulesFeaturesPlans {
                id
                Add_Plan {
                  Plan_CTA_Label
                  Plan_Features
                  Plan_Intro
                  Plan_Title
                  Recommended
                  Plan_CTA_Link {
                    id
                  }
                }
                Plans_Module_Heading_Content
                Plans_Module_Style
                Plans_Modules_Info_Title
              }
              ... on GLSTRAPI_ComponentModulesImageBelowContent {
                id
                Content
                Animation_Type
                Content_Position
                Module_Width
                Bg_Color
                Video
                Add_Image_here {
                  url
                  alternativeText
                }
              }
              ... on GLSTRAPI_ComponentModulesFeaturedProperties {
                id
                Featured_Properties_Title_Override
                Featured_Property_Type
              }
              ... on GLSTRAPI_ComponentModulesStaticContent {
                Add_Static_Content
              }
              ... on GLSTRAPI_ComponentModulesRightSidebar {
                Right_Sidebar_CTA_Label
                Right_Sidebar_Content
                Right_Sidebar_Video_URL
                Right_Sidebar_CTA_Link {
                  id
                }
                Right_Sidebar_Video_Background_Image {
                  url
                  alternativeText
                }
              }
              ... on GLSTRAPI_ComponentModulesHeader {
                id
                Header_Content
              }
              ... on GLSTRAPI_ComponentModulesForm {
                id
                Form_Header_Content
                Form_Info_Title
                Select_Form
                Form_Background_Image {
                  url
                  alternativeText
                  url_sharp {
                    childImageSharp {
                      gatsbyImageData(formats: AUTO
                        width: 1800
                        placeholder: BLURRED
                        quality: 80
                        layout: FIXED
                        transformOptions: {cropFocus: CENTER, fit: COVER}
                      )
                      fluid(quality: 90, maxWidth: 1800, maxHeight:1050,cropFocus:ENTROPY, fit:INSIDE) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesPeopleCategoryToShow {
                id
                Select_Category_here {
                  Name
                  URL
                  Sort
                }
              }
              ... on GLSTRAPI_ComponentModulesBannerImageWithCtas {
                id
                Banner_Image {
                  url
                  url_sharp {
                      childImageSharp {
                        gatsbyImageData
                    }
                  }
                }
                Sub_Title
                Title
                Ctas {
                  Cta_Label
                  Cta_Link
                  Target
                }
              }
              ... on GLSTRAPI_ComponentModulesCareerVideos {
                id
                Add_New_Video {
                  Career_Video_Details
                  Career_Video_URL
                  Career_Video_Background_Image {
                    url
                    alternativeText
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesJobsList {
                id
                Jobs_List_Content
                Jobs_List_Info_Title
                Show_All_Jobs
              }
              ... on GLSTRAPI_ComponentModulesGridServicesWithIcon {
                id
                Grid_Services_Intro
                Add_Services {
                  Choose_Icon
                  Content
                }
              }
              ... on GLSTRAPI_ComponentModulesImageRightContent {
                  id
                  Add_Image_here {
                    url
                  }
                  Content
                  Ctas {
                    Cta_Label
                    Cta_Link
                    Target
                  }
              }
              ... on GLSTRAPI_ComponentModulesMultipleImagesWithContent {
                id
                Add_Rightside_Content
                Add_Images_here {
                  Image_2_Video_URL
                  Image_1 {
                    url
                    alternativeText
                  }
                  Image_2 {
                    alternativeText
                    url
                  }
                }
              }
              ... on GLSTRAPI_ComponentComponentGuideBlocks {  
                id
                Top_Title
                Top_Description
                Guides {
                  Title
                  Description
                  Image {
                    url
                    alternativeText
                  }
                  File {
                    alternativeText
                    url
                  }
                }
              }
            }
        }


      }
  }
`
