import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"

import Slider01 from "../../../images/propertyDetails/property01.jpg"
import Slider001 from "../../../images/propertyDetails/tab-property01.jpg"
import Slider002 from "../../../images/propertyDetails/tab-property02.jpg"
import Slider0001 from "../../../images/propertyDetails/desk-property01.jpg"
import Slider0002 from "../../../images/propertyDetails/desk-property02.jpg"
import Slider0003 from "../../../images/propertyDetails/desk-property03.jpg"

import Sliderdesk from "../../../images/propertyDetails/desktop.jpg"



import Slider from "react-slick";
import "./SimilarProperties.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const SIMILAR_NEWS = gql`
query SIMNEWS($URL: String!){
    newsBlogs(where:{URL_ne: $URL},limit:4){
        id
        Title
        Date
        URL
        Tile_Image {
          url
          alternativeText
        }
        Categories {
          Name
          URL
        }
        imagetransforms
    }
}
`;



function SimilarNews(props) {

    const { loading, error, data } = useQuery(SIMILAR_NEWS, {
        variables: { URL: props.slug }
      });

    const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                   
                },
            },
            
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                   
                },
            },
        ],
    };

    if (loading) return (
        <p>loading</p>
    )

    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

                <section className="similar-properties similar-news">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <h2><i>More</i> news.</h2>
                                <div className="slick-wrap">
                                <Slider {...settings}>
                                {data.newsBlogs && data.newsBlogs.map((news, i) => {
                                   
                                    var months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
                                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

                                    var selectedMonthName = months[parseInt(news.Date.slice(5,7))];
                                    
                                    var cat_list = ''
                                    var cats = news.Categories
                                    
                                    for(var i=0; i < cats.length; i++) {
                                        var full_name = cats[i].Name
                                        cat_list += (cats[i].Name) + `${cats.length == 1 || i+1 == cats.length ? '' : ', '}`
                                    }	
                                    
                                    return (
                                    <Link to={`../${news.URL}`}>
                                    <div className="properties img-zoom">
                                        <picture>
                                            <source media="(min-width:1500px)" srcSet={news.Tile_Image.url} />
                                            <source media="(min-width:992px)" srcSet={news.Tile_Image.url} />
                                            <source media="(min-width:768px)" srcSet={news.Tile_Image.url} />
                                            <img src={news.Tile_Image.url} alt={news.Title} />
                                        </picture>
                                        
                                        {/* <div className="gallery-text">
                                            <span className="date">
                                                {news.Date.slice(8,10)}
                                            </span>
                                            <span className="month">
                                                {selectedMonthName}
                                            </span>
                                        </div> */}

                                        <div className="card-details">
                                            <h3>
                                                {news.Title}                                                
                                            </h3>
                                            {/* <div className="cats"># {cat_list}</div> */}
                                        </div>
                                    </div>
                                    </Link>
                                    )
                                })}
                                </Slider>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </ScrollAnimation>
        </React.Fragment>
    );
};

export default SimilarNews;
