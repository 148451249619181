import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import gql from "graphql-tag"
import logoBlack from "../../images/redbrik-new-logo.svg"
import Facebook from "../../images/social/facebook.png";
import Twitter from "../../images/social/twitter.png";
import Instagram from "../../images/social/instagram.png";
import _ from "lodash"
import parse from 'html-react-parser';
import SEO from "../seo"
import { useLocation } from "@reach/router"
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon
} from "react-share";

import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import SimilarGuides from "../../components/ProperyDetails/SimilarProperties/SimilarGuides"
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import Newsletter from "../StaticPage/StayUpdate/StayUpdate"
import GuideForm from "../forms/guide-detail-form"

import quote from "../../images/quote.jpg"


import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const SINGLE_NEWS = gql`
query GETGUIDE($URL: String!){
    guides(where:{URL: $URL}){
        id 
        Title
        URL
        Meta_Title
        Meta_Description 
        Description 
        Image {
            url
            alternativeText
        }
        File {
            alternativeText
            url
        } 
        Banner_Image {
          url
          alternativeText
        } 
        Add_Content {
            ...on ComponentModulesStaticContent {
            Add_Static_Content
            } 
            ...on ComponentModulesAddImage {
                Image {
                    url
                    alternativeText
                }
            }
        }
    }
}
`;

function GuideLandingPage(props) {

    const { loading, error, data } = useQuery(SINGLE_NEWS, {
        variables: { URL: props.slug }
    });

    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
    const [Shareicons, setShareicons] = React.useState(false);

    const openShareicons = () => {
        setShareicons(true);
        if (Shareicons === true) {
            setShareicons(false);
        }
    }

    // Pdf download function
    const downloadFUnction = (pdfFile, title) => {
        if (pdfFile) {
            // using Java Script method to get PDF file
            fetch(pdfFile).then(response => {
                response.blob().then(file => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(file);
                    const PDF = pdfFile.replace(pdfFile, title)

                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = PDF;
                    alink.click();
                })
            })
        }
    }

    const getBreadcrumb = (list) => {
        let bread = [
            { Title: "Guide", Slug: "cost-of-living" }]
        if (list) {
            bread.push({ Title: list[0].Title, Slug: `guide/${list[0].URL}` })
        }
        return bread;
    }
    if (loading) return (
        <section className={"loader-wrapper"}>
            <div id="loader-wrapper">
                <div id="loader" class="new-loader">
                    <img className="logo-white loader-logo" src={logoBlack} alt="logo" />
                </div>
                <div class="loader-section section-left"></div>
                <div class="loader-section section-right"></div>
            </div>
        </section>
    )

    return (
        <React.Fragment>
            <div className=" news-details">
                <SEO title={props.Meta_Title} description={props.Meta_Description} />
                <Header />
                <div className="collection-landing news-details">
                    <Breadcrumb menus={props.Choose_Menu} />

                    <Container className="content"> 
                        <Row>
                            <Col lg={1}>
                            </Col>
                            <Col lg={10}>
                                <div className="heading">
                                    <div className="main-title">
                                        <h1>{props.Banner_Title_Heading}</h1>
                                        <Row>
                                            <Col lg={8}>
                                                <div className="social">
                                                    {props.Page_Tags && (
                                                    <><span className="sm-info">
                                                        {props.Page_Tags}
                                                    </span>
                                                    <span className="sm-info divider"> / </span></>)}
                                                    <a href="javascript:void(0)" className="propertyShare" onClick={openShareicons}>Share this guide</a>
                                                    {Shareicons &&
                                                        <div onClick={openShareicons} className="social-share">
                                                            <FacebookShareButton url={shareurl} className="my-share-button facebook-share">
                                                                <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                                            </FacebookShareButton>
                                                            <TwitterShareButton url={shareurl} className="my-share-button twitter-share">
                                                                <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                                            </TwitterShareButton>

                                                        </div>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                {props.Banner_Image &&
                                    <div className="news-main-banner">
                                        <img src={props.Banner_Image.url} alt={props.Banner_Image.alternativeText} />
                                    </div>
                                }
                                {props.Banner_Main_Content &&
                                    <div className="row">
                                        <div className="static-content col-lg-12">
                                            {parse(props.Banner_Main_Content)}
                                        </div>
                                    </div>
                                }
                                <section className="update download-broucher">
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <div className="stay-update">
                                                    <div className="form-content">
                                                        <h2>Need help <span className="sm-text"> with selling your property? </span></h2>
                                                        <p>Our team are always here. Leave your details and we’ll be in touch.</p>
                                                    </div>
                                                    <div className="form-wrapper guide-form">
                                                        <GuideForm formLabel="Guide Form" pdfFile={null} pdfTitle={data.title} downloadFUnction={() => downloadFUnction(null, data.Title)} to_email_id={process.env.MAIL_VIEWING_TO} />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </section>

                                {props.Modules.map((content, index) => {
                                    return (
                                        <>
                                            {content.Add_Static_Content &&
                                                <div className="row">
                                                    <div className="static-content col-lg-12">
                                                        {parse(content.Add_Static_Content)}
                                                    </div>
                                                </div>
                                            }
                                            {content.ModuleImage &&
                                                <div className="static-content-images row">
                                                    {content.ModuleImage.map((image, index) => {
                                                        return (
                                                            <div className={`stati ${content.ModuleImage.length > 1 ? "col md-6 col-lg-6" : "col-lg-12"}`}>
                                                                <img src={image.url} alt="" />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </>
                                    )
                                })}

                                <div className="row">
                                    <div className="col-lg-12">
                                        <Newsletter />
                                    </div>

                                </div>
                            </Col>
                            <Col lg={1}>
                            </Col>
                        </Row>
                    </Container>

                    <SimilarGuides slug={'props.slug'} />
                </div>
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default GuideLandingPage;
